import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SlideShow from "../components/Slideshow"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <h1>Ejemplos de Slides con gatsby</h1>

    <ul>
      <li>
        Usa <code>useStaticQuery</code> para obtener todas las imagenes alojadas
        en una carpeta las carga en un array y luego usa ese array para iterar
        sobre ellas y mostrarlas una a una
      </li>
      <li>
        Las imagenes son cargadas al componente
        <a href="https://using-gatsby-image.gatsbyjs.org/">gatsby-image</a>{" "}
      </li>
    </ul>
    <SlideShow />

    <Link to="/">volver al home</Link>
  </Layout>
)

export default SecondPage
